import { combineReducers } from 'redux-immutable';
import {
  FAILED_STATUS,
  GET_LOAN_DOCUMENT_BY_TOKEN,
  GET_LOAN_DOCUMENT_BY_TOKEN_FAILED,
  GET_LOAN_DOCUMENT_BY_TOKEN_SUCCESS,
  IS_FETCHING_STATUS,
  SUCCESSFUL_STATUS
} from './constants';
import { SAVE_ENTRY_TOKEN } from 'redux/tokens/constants';
import { RESET_APP } from 'redux/app/constants';

const getLoanDocumentByTokenStateReducer = (state = null, action) => {
  switch (action.type) {
    case GET_LOAN_DOCUMENT_BY_TOKEN:
      return IS_FETCHING_STATUS;
    case GET_LOAN_DOCUMENT_BY_TOKEN_FAILED:
      return FAILED_STATUS;
    case GET_LOAN_DOCUMENT_BY_TOKEN_SUCCESS:
      return SUCCESSFUL_STATUS;
    case SAVE_ENTRY_TOKEN:
    case RESET_APP:
      return null;
    default:
      return state;
  }
};

export default combineReducers({
  getLoanDocumentByTokenState: getLoanDocumentByTokenStateReducer
});
