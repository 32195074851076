import React, { memo, useEffect } from 'react';
import { replace } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Card, Result, Spin, Typography } from 'antd';
import { MailOutlined } from '@ant-design/icons';

import SubscriptionForm from './SubscriptionForm';
import NotFound from 'components/common/Pages/NotFound';

import {
  selectSubscriptionEmail,
  selectSubscriptionState,
  selectSubscriptionTokenVerified,
  selectSubscriptionTokenVerifiedIsFetching
} from 'redux/electronicMsgSubscription/selectors';

import { getSubscriptionTokenData } from 'redux/electronicMsgSubscription/actions';
import { saveEntryToken } from 'redux/tokens/actions';

import { ROUTES } from 'consts/routes';
import { MAIN_COLOR } from 'theme/variables';
import { selectCurrentToken } from 'redux/tokens/selectors';
import LoadingPage, { antIcon } from 'components/common/LoadingPage';

const { Title, Text } = Typography;

const ErrorPage = () => {
  return (
    <Result
      status="error"
      title="Something went wrong opening the link provided."
      extra={
        <>
          <Card style={{ width: '70%', margin: '0 auto 20px' }}>
            <Text strong>
              We're sorry. The token used is wrong or is expired. Try again or
              contact us.
            </Text>
          </Card>
          <Text type="secondary">
            If you feel that the email was sent to you in error, please ignore
            this page.
          </Text>
        </>
      }
    />
  );
};

function InitUnsubscribe() {
  const subscriptionTokenVerifiedIsFetching = useSelector(
    selectSubscriptionTokenVerifiedIsFetching
  );
  const subscriptionEmail = useSelector(selectSubscriptionEmail);
  const subscriptionState = useSelector(selectSubscriptionState);

  return (
    <Result
      icon={
        <MailOutlined
          style={{ color: MAIN_COLOR, fontSize: 80 }}
          fill={MAIN_COLOR}
        />
      }
      title={subscriptionState ? 'Manage your email subscription' : null}
      extra={
        <>
          <Card style={{ width: '70%', margin: '0 auto 20px' }}>
            {subscriptionTokenVerifiedIsFetching ? (
              <Spin indicator={antIcon} />
            ) : (
              <>
                <Title level={5}>
                  {subscriptionState
                    ? 'Email subscription for'
                    : 'You are successfully unsubscribed for'}{' '}
                  {subscriptionEmail}.
                </Title>
                <p>
                  If your email address is not {subscriptionEmail}, then this
                  email was most likely forwarded to you.
                </p>

                {subscriptionState ? (
                  <SubscriptionForm style={{ marginTop: 20 }} />
                ) : null}
              </>
            )}
          </Card>
          {subscriptionState ? (
            <Text type="secondary">
              Please allow up to 10 business days for your request to take
              effect.
              <br />
              If you feel that the email was sent to you in error, please ignore
              this page.
            </Text>
          ) : null}
        </>
      }
    />
  );
}

function ElectronicMsgManagement() {
  const dispatch = useDispatch();
  const { token } = useParams();
  const subscriptionTokenVerified = useSelector(
    selectSubscriptionTokenVerified
  );
  const subscriptionTokenVerifiedIsFetching = useSelector(
    selectSubscriptionTokenVerifiedIsFetching
  );
  const currentToken = useSelector(selectCurrentToken);

  useEffect(() => {
    if (token) {
      dispatch(replace(ROUTES.UNSUBSCRIBE.BASE));
      dispatch(saveEntryToken(token));
      dispatch(getSubscriptionTokenData());
    }
  }, []);

  if (token) {
    return (
      <div
        style={{
          display: 'flex',
          height: '400px',
          justifyContent: 'center'
        }}
      >
        <LoadingPage />
      </div>
    );
  }

  return !subscriptionTokenVerified ? (
    subscriptionTokenVerifiedIsFetching ? (
      <LoadingPage />
    ) : currentToken ? (
      <ErrorPage />
    ) : (
      <NotFound />
    )
  ) : (
    <InitUnsubscribe />
  );
}

export default memo(ElectronicMsgManagement);
