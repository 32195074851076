import { createSelector } from 'reselect';

const selectEMSubscriptionDomain = () => state =>
  state.get('electronicMsgSubscription');

const selectEMSubscription = createSelector(
  selectEMSubscriptionDomain(),
  emSubscriptionDomain => emSubscriptionDomain.toJS()
);

const selectSubscriptionTokenVerified = createSelector(
  selectEMSubscription,
  emSubscription => emSubscription.subscriptionTokenVerified
);

const selectSubscriptionTokenVerifiedIsFetching = createSelector(
  selectEMSubscription,
  emSubscription => emSubscription.subscriptionTokenVerifiedIsFetching
);

const selectSubscriptionEmail = createSelector(
  selectSubscriptionTokenVerified,
  subscriptionTokenVerified => subscriptionTokenVerified?.email
);

const selectSubscriptionId = createSelector(
  selectSubscriptionTokenVerified,
  subscriptionTokenVerified => subscriptionTokenVerified?.subscriptionId
);

const selectSubscriptionState = createSelector(
  selectSubscriptionTokenVerified,
  subscriptionTokenVerified => subscriptionTokenVerified?.flags?.Subscription
);

const selectHandleElectronicMsgSubscriptionIsFetching = createSelector(
  selectEMSubscription,
  emSubscription => emSubscription.handleElectronicMsgSubscriptionIsFetching
);

export default selectEMSubscription;
export {
  selectSubscriptionTokenVerified,
  selectSubscriptionTokenVerifiedIsFetching,
  selectSubscriptionEmail,
  selectSubscriptionId,
  selectSubscriptionState,
  selectHandleElectronicMsgSubscriptionIsFetching
};
