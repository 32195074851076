import { makeActionCreator } from 'redux/utils';
import {
  GET_LOAN_DOCUMENT_BY_TOKEN,
  GET_LOAN_DOCUMENT_BY_TOKEN_SUCCESS,
  GET_LOAN_DOCUMENT_BY_TOKEN_FAILED
} from './constants';

export const getLoanDocumentByToken = makeActionCreator(
  GET_LOAN_DOCUMENT_BY_TOKEN,
  'payload'
);
export const getLoanDocumentByTokenSuccess = makeActionCreator(
  GET_LOAN_DOCUMENT_BY_TOKEN_SUCCESS,
  'payload'
);
export const getLoanDocumentByTokenFailed = makeActionCreator(
  GET_LOAN_DOCUMENT_BY_TOKEN_FAILED,
  'error'
);
