import ElectronicMsgSubscription from 'components/views/ElectronicMsgSubscription';
import { ROUTES } from 'consts/routes';

export const unSubscribeBaseRoute = {
  key: 'unsubscribe_electronic_messages_route',
  path: ROUTES.UNSUBSCRIBE.BASE,
  title: 'Electronic messages management',
  exact: true,
  component: ElectronicMsgSubscription
};

export const unSubscribeWithTokenRoute = {
  key: 'unsubscribe_electronic_messages_route',
  path: ROUTES.UNSUBSCRIBE.WITH_TK,
  title: 'Electronic messages management',
  exact: true,
  component: ElectronicMsgSubscription
};

export const unSubscribeRoutes = [
  unSubscribeBaseRoute,
  unSubscribeWithTokenRoute
];
