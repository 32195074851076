import { push, getLocation } from 'connected-react-router';

import { showInfoNotification } from 'utils/notifications';
import {
  NOT_FOUND_NOMENCLATURE,
  UNKNOWN_ERROR_NOMENCLATURE
} from './errorsNomenclature';

export const HTTP_STATUS = {
  NOT_AUTHORIZED: 401,
  ACCESS_DENIED: 403,
  NOT_FOUND: 404,
  WRONG_DATA: 400,
  BAD_GATEWAY: 502,
  OK: 200,
  NO_CONTENT: 204
};

/**
 * Request interceptor
 * @param {Object} config
 */

export const requestInterceptor = user => config => {
  const newConfig = Object.assign({}, config);

  newConfig.headers.Authorization = `Bearer ${user.access_token}`;
  return newConfig;
};

/**
 * Success response interceptor
 * @param {Object} response
 */
export function responseOk(response) {
  response.isSuccess = true;
  return response; // No logic here
}

const formatError = error => {
  const { config, response } = error;
  const errorInfoSummary = {
    url: `${config.baseURL}${config.url}`,
    payload: config.data
  };

  if (response?.data?.type) {
    return {
      ...response.data,
      fromResponse: true
    };
  }
  return {
    ...errorInfoSummary,
    type: UNKNOWN_ERROR_NOMENCLATURE,
    fromResponse: false
  };
};
/**
 * Error response interceptor
 * @param {Object} store
 */
export const responseError = store => error => {
  const { response } = error;

  let errorInfoSummary = {};
  if (response) {
    errorInfoSummary = formatError(error);
  }

  if (response?.status) {
    let message = {
      title: 'An error occured',
      description: 'The operation could not be performed.',
      isFromResponse: true
    };

    switch (response.status) {
      case HTTP_STATUS.NOT_AUTHORIZED:
        const state = store.getState().toJS();
        const location = getLocation(state);
        store.dispatch(push('/', location));
        showInfoNotification(
          'You are not authorized to access this page or your session has expired.',
          'Info',
          'unauthorized'
        );

        return Promise.reject({
          ...response,
          isNotAuthorized: true,
          errorInfoSummary
        });

      case HTTP_STATUS.ACCESS_DENIED:
        showInfoNotification(
          'Sorry, you do not have access to this action.',
          'Info',
          'unauthorized'
        );
        // store.dispatch(push('/', location));
        return Promise.reject({
          ...response,
          isNotAuthorized: true,
          errorInfoSummary
        });

      case HTTP_STATUS.WRONG_DATA:
        if (response.data.showToUser) {
          message.title = response.data.title;
          message.description = response.data.message;
        }

        const errorData = JSON.stringify(response.data);
        return Promise.reject({
          ...response.data,
          errorData,
          errorMessage: message,
          errorInfoSummary
        });

      case HTTP_STATUS.NOT_FOUND:
        if (!response?.data) {
          return Promise.reject({
            ...response,
            notFound: true,
            errorInfoSummary
          });
        }

        if (response.data.type === NOT_FOUND_NOMENCLATURE) {
          response.data.notFound = true;
        }

        return Promise.reject({
          ...response.data,
          notFound: true,
          errorInfoSummary
        });

      default:
        break;
    }
  }
  return Promise.reject({ ...error, errorInfoSummary: formatError(error) });
};
