import React from 'react';
import { notification } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { Modal } from 'antd';

export const ALERT_NOTIFICATION_TYPE = 'ALERT';
export const WARNING_NOTIFICATION_TYPE = 'WARNING';
export const SUCCESS_NOTIFICATION_TYPE = 'SUCCESS';
export const INFO_NOTIFICATION_TYPE = 'INFO';

const ALERT_STYLE =
  'color: #e09999; background:#5f1d1d; border:1px solid #e09999; padding-left: 10px; padding-right: 10px;';
const WARNING_STYLE =
  'color: #292925; background:orange; border:1px solid #292925; padding-left: 10px; padding-right: 10px;';
const SUCCESS_STYLE =
  'color: white; background:green; border:1px solid white; padding-left: 10px; padding-right: 10px;';
const INFO_STYLE = 'padding-left: 10px; padding-right: 10px;';

notification.config({
  placement: 'topRight',
  duration: 5
});

export const showAlertWithConfirm = description => {
  Modal.info({
    title: 'A problem has occurred.',
    content: (
      <div>
        {description ? <p>{description}</p> : null}
        <p>For more information contact us.</p>
      </div>
    ),
    onOk() {}
  });
};

/**
 * Shows success notification
 * @param {string} description
 * @param {string} message
 * @param {Number} duration
 */
export const showSuccessNotification = (
  description = 'Operation performed successfully.',
  message = 'Success',
  duration = 5
) => {
  notification.success({
    className: 'notification-success',
    message,
    description,
    duration
  });
};

/**
 * Shows error notification
 * @param {string} description
 * @param {string} message
 * @param {Number} duration
 */
export const showErrorNotification = (
  description = 'Failed operation.',
  message = 'Error',
  duration = 5
) => {
  notification.error({
    className: 'notification-error',
    message,
    description,
    duration
  });
};

/**
 * Shows info notification
 * @param {string} description
 * @param {string} message
 * @param {Number} duration
 * @param {string} key
 */
export const showInfoNotification = (
  description = 'Info message.',
  message = 'Info',
  duration = 15,
  key = uuidv4()
) => {
  notification.info({
    className: 'notification-info',
    key,
    message,
    description,
    duration
  });
};

/**
 * Shows an error notification from response data
 * @param {Object} data
 */
export const showErrorNotificationFromResponse = (
  errorData,
  message = 'For more information contact us.'
) => {
  if (!errorData) {
    showErrorNotification();
    return;
  }
  // Do not show for not authorized or not found errors
  if (errorData.isNotAuthorized || errorData.notFound) {
    // showErrorNotification(message, 'Sorry, an error has occurred', '');
    return;
  }
  if (errorData.showToUser) {
    showErrorNotification(errorData.message, errorData.title, '');
    return;
  } else {
    showErrorNotification(message, 'Sorry, an error has occurred', '');
    return;
  }
};

export function logMessageInConsole(message, log_type) {
  let style_by_type = {};
  switch (log_type) {
    case ALERT_NOTIFICATION_TYPE:
      style_by_type = ALERT_STYLE;
      break;
    case WARNING_NOTIFICATION_TYPE:
      style_by_type = WARNING_STYLE;
      break;
    case SUCCESS_NOTIFICATION_TYPE:
      style_by_type = SUCCESS_STYLE;
      break;
    default:
      style_by_type = INFO_STYLE;
      break;
  }

  console.log(`%c${message}`, `${style_by_type}`);
}
