import React, { memo } from 'react';

import { Layout } from 'antd';

import AppHeader from './AppHeader';
import AppRoutes from './AppRoutes';
import AppFooter from './AppFooter';
import AppContent from './AppContent';

function AppLayout() {
  return (
    <Layout
      style={{
        margin: 0,
        minHeight: '100vh'
      }}
    >
      <AppHeader />
      <Layout>
        <AppRoutes component={AppContent} />
        <AppFooter />
      </Layout>
    </Layout>
  );
}

export default memo(AppLayout);
