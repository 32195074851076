const resourceBase = '/rs';
const appsBase = '/app/download';

export const ROUTES = {
  HOME: '/',
  STATEMENT: '/statement',
  DISCLOSURES: '/disclosures',
  COPPA_DISCLOSURE: '/coppa_disclosure',
  PRIVACY_POLICY: '/privacy_policy',
  NOT_AUTHORIZED: '/403',
  NOT_FOUND: '/404',
  UNSUBSCRIBE: {
    BASE: '/unsubscribe',
    WITH_TK: '/unsubscribe/:token'
  },
  RESOURCES: {
    LOAN: {
      DOCUMENTS: {
        BASE: `${resourceBase}/loan/document/view`,
        WITH_TK: `${resourceBase}/loan/document/view/:token`
      }
    },
    APPS: {
      WITH_TYPE: `${appsBase}/:clientType`
    }
  }
};
