import { createGlobalStyle } from 'styled-components';

/* eslint no-unused-expressions: 0 */
const GlobalStyles = createGlobalStyle`
  html, body {
    width: 100%;
    min-height: 100%;
    margin: 0;
    padding: 0;
}
`;

export default GlobalStyles;
