import React from 'react';
import { Button, Checkbox, Divider, Form, Space, Spin, Typography } from 'antd';
import { handleElectronicMessageSubscription } from 'redux/electronicMsgSubscription/actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectHandleElectronicMsgSubscriptionIsFetching,
  selectSubscriptionEmail,
  selectSubscriptionId,
  selectSubscriptionState
} from 'redux/electronicMsgSubscription/selectors';
const { Text } = Typography;

// ------ Option for subscribe/unsubscribe

// export default function SubscriptionForm({ ...props }) {
//   const handleElectronicMsgSubscriptionIsFetching = useSelector(
//     selectHandleElectronicMsgSubscriptionIsFetching
//   );
//   const subscriptionState = useSelector(selectSubscriptionState);
//   const subscriptionId = useSelector(selectSubscriptionId);
//   const email = useSelector(selectSubscriptionEmail);

//   const dispatch = useDispatch();

//   const handleOnFinish = values => {
//     dispatch(
//       handleElectronicMessageSubscription({
//         subscription: values.subscription,
//         data: { email, subscriptionId }
//       })
//     );
//   };

//   return (
//     <div {...props}>
//       <Spin spinning={handleElectronicMsgSubscriptionIsFetching}>
//         {/* <Text strong>
//           If you wish to change your electronic message subscription you can
//           modify the following option:
//         </Text> */}
//         <Form
//           onFinish={handleOnFinish}
//           initialValues={{ subscription: subscriptionState }}
//         >
//           <Form.Item name="subscription" valuePropName="checked">
//             <Checkbox>Continue to send me electronic messages.</Checkbox>
//           </Form.Item>
//           <Divider />

//           <Form.Item>
//             <Button
//               htmlType="submit"
//               type="primary"
//               loading={handleElectronicMsgSubscriptionIsFetching}
//             >
//               Update
//             </Button>
//           </Form.Item>
//         </Form>
//       </Spin>
//     </div>
//   );
// }

// ------ Option for only unsubscribe
export default function SubscriptionForm({ style, ...props }) {
  const handleElectronicMsgSubscriptionIsFetching = useSelector(
    selectHandleElectronicMsgSubscriptionIsFetching
  );
  const subscriptionId = useSelector(selectSubscriptionId);
  const email = useSelector(selectSubscriptionEmail);

  const dispatch = useDispatch();

  const handleUnsubscribe = values => {
    dispatch(
      handleElectronicMessageSubscription({
        subscription: false,
        data: { email, subscriptionId }
      })
    );
  };

  return (
    <div
      style={{
        ...style,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Spin spinning={handleElectronicMsgSubscriptionIsFetching}>
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
          <Text strong>
            If you wish to unsubscribe from our electronic messages, please
            click the action below:
          </Text>
          <Button
            type="primary"
            loading={handleElectronicMsgSubscriptionIsFetching}
            onClick={handleUnsubscribe}
          >
            Unsubscribe
          </Button>
        </Space>
      </Spin>
    </div>
  );
}
