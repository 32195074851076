import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { useRouteMatch } from 'react-router-dom';
import { Layout } from 'antd';

const { Content } = Layout;

function ContentWrapper({ component }) {
  const match = useRouteMatch();

  const ComponentToRender = component;
  return <ComponentToRender match={match} />;
}

const AppContent = ({ route: { component } }) => {
  return (
    <Content>
      <ContentWrapper component={component} />
    </Content>
  );
};

AppContent.propTypes = {
  pageTitle: PropTypes.string,
  showToggleIcon: PropTypes.bool,
  toggleIconClick: PropTypes.func
};

export default memo(AppContent);
