import { ROUTES } from 'consts/routes';
export const PRIVACY_POLICY_URL = process.env.REACT_APP_PRIVACY_POLICY_URL;

export const APP_NAME = 'eSIGN';

export const TIMES = {
  MINUTE: '60000',
  HALF_MINUTE: '30000',
  QUARTER_MINUTE: '15000',
  SECOND: '1000',
  FIVE_MINUTES: '300000',
  MINUTES_2: '120000'
};

export const UNKNOWN_ALERT_NOMENCLATURE = 0;
export const ERROR_ALERT_NOMENCLATURE = 1;
export const WARNING_ALERT_NOMENCLATURE = 2;
export const INFORMATION_ALERT_NOMENCLATURE = 3;
export const SUCCESS_ALERT_NOMENCLATURE = 4;

export const APPLICANT_PREFIX = 'applicant';
export const CO_APPLICANT_PREFIX = 'coApplicant';
export const SALES_REP_PREFIX = 'salesRep';
export const APPLICATION_DETAILS_PREFIX = 'applicationDetails';

export const BORROWER_NOMENCLATURE = '18';
export const COBORROWER_NOMENCLATURE = '28';

export const APPLICANT_TITLE = {
  [BORROWER_NOMENCLATURE]: 'Applicant Information',
  [COBORROWER_NOMENCLATURE]: 'Co-Applicant Information'
};

export const APPLICANT_NOMENCLATURE_PREFIX_MAP = {
  [BORROWER_NOMENCLATURE]: APPLICANT_PREFIX,
  [COBORROWER_NOMENCLATURE]: CO_APPLICANT_PREFIX
};

export const legalLinks = [
  ROUTES.STATEMENT,
  ROUTES.DISCLOSURES,
  ROUTES.COPPA_DISCLOSURE,
  ROUTES.PRIVACY_POLICY
];

export const legalLinksName = {
  [ROUTES.STATEMENT]: 'Statement on Use of Electronic Records',
  [ROUTES.DISCLOSURES]: 'General Application Disclosures',
  [ROUTES.COPPA_DISCLOSURE]: 'COPPA Disclosure',
  [ROUTES.PRIVACY_POLICY]: 'Privacy Policy'
};

export const RESET_APP = 'balder/App/RESET_APP';
