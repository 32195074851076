import { getEmailUnsubscribeData, handleElectronicMessageRequest } from 'api';
import { call, put, takeLatest } from 'redux-saga/effects';
import { resetCurrentToken } from 'redux/tokens/actions';
import { showSuccessNotification } from 'utils/notifications';
import { apiRequest } from 'utils/sagas';
import {
  getSubscriptionTokenData,
  getSubscriptionTokenDataFailed,
  getSubscriptionTokenDataSuccess,
  handleElectronicMessageSubscriptionFailed,
  handleElectronicMessageSubscriptionSuccess
} from './actions';
import {
  GET_SUBSCRIPTION_TOKEN_DATA,
  HANDLE_ELECTRONIC_MSG_SUBSCRIPTION
} from './constants';

function* getUnsubscribeTokenSaga() {
  yield takeLatest(GET_SUBSCRIPTION_TOKEN_DATA, function* () {
    const response = yield call(apiRequest, getEmailUnsubscribeData, []);

    if (!response.isSuccess) {
      yield put(getSubscriptionTokenDataFailed(response));
      yield put(resetCurrentToken());
      return;
    }

    yield put(getSubscriptionTokenDataSuccess(response.data));
  });
}

function* handleSubscriptionSaga() {
  yield takeLatest(HANDLE_ELECTRONIC_MSG_SUBSCRIPTION, function* ({ payload }) {
    const response = yield call(apiRequest, handleElectronicMessageRequest, [
      payload.subscription,
      payload.data
    ]);

    if (!response.isSuccess) {
      yield put(handleElectronicMessageSubscriptionFailed(response));
      return;
    }

    yield put(handleElectronicMessageSubscriptionSuccess(response.data));
    // Option for only unsubscribe
    yield put(getSubscriptionTokenData());

    // Option for subscribe/unsubscribe
    // showSuccessNotification(
    //   'The subscription has been updated.',
    //   'Update successfully'
    // );
  });
}

const sagas = [getUnsubscribeTokenSaga, handleSubscriptionSaga];

export default sagas;
