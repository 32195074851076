import React from 'react';
import styled from 'styled-components';

import { Typography } from 'antd';

import {
  FONT_COLOR,
  FONT_COLOR_LIGHT,
  FONT_SIZE,
  FONT_SIZE_SMALL,
  FONT_SIZE_SMALLEST
} from 'theme/variables';

const TextAnt = styled(Typography.Text)`
  color: ${FONT_COLOR};
`;

export const Title = styled(Typography.Title)`
  &.ant-typography {
    color: ${FONT_COLOR};
    font-size: ${FONT_SIZE};
  }
`;

export const SubTitle = styled(Typography.Title)`
  &.ant-typography {
    color: ${FONT_COLOR_LIGHT};
    font-weight: normal;
    font-size: ${FONT_SIZE_SMALL};
  }
`;

export const BigTitle = ({ children, ...props }) => {
  return (
    <Title level={2} {...props}>
      {children}
    </Title>
  );
};

export const BigSubtitle = ({ children, ...props }) => {
  return (
    <SubTitle level={4} {...props}>
      {children}
    </SubTitle>
  );
};

export const Text = ({ children, ...props }) => {
  return <TextAnt {...props}>{children}</TextAnt>;
};

export const Copyright = styled.span`
  font-size: ${FONT_SIZE_SMALLEST};
  text-align: center;
  color: ${FONT_COLOR_LIGHT};
  display: block;
`;
