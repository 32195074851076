import { createSelector } from 'reselect';
import {
  FAILED_STATUS,
  IS_FETCHING_STATUS,
  SUCCESSFUL_STATUS
} from './constants';

const selectResourcesDomain = () => state => state.get('resources');

const selectResources = createSelector(selectResourcesDomain(), resources =>
  resources.toJS()
);

const selectLoanDocumentByTokenIsFetching = createSelector(
  selectResources,
  resources => resources.getLoanDocumentByTokenState === IS_FETCHING_STATUS
);

const selectLoanDocumentByTokenFailed = createSelector(
  selectResources,
  resources => {
    return resources.getLoanDocumentByTokenState === FAILED_STATUS;
  }
);

const selectLoanDocumentByTokenSuccess = createSelector(
  selectResources,
  resources => resources.getLoanDocumentByTokenState === SUCCESSFUL_STATUS
);

export default selectResources;

export {
  selectLoanDocumentByTokenIsFetching,
  selectLoanDocumentByTokenFailed,
  selectLoanDocumentByTokenSuccess
};
