import { LOCATION_CHANGE } from 'connected-react-router';
import { fromJS } from 'immutable';
import { combineReducers } from 'redux-immutable';
import {
  GET_SUBSCRIPTION_TOKEN_DATA,
  GET_SUBSCRIPTION_TOKEN_DATA_FAILED,
  GET_SUBSCRIPTION_TOKEN_DATA_SUCCESS,
  HANDLE_ELECTRONIC_MSG_SUBSCRIPTION,
  HANDLE_ELECTRONIC_MSG_SUBSCRIPTION_FAILED,
  HANDLE_ELECTRONIC_MSG_SUBSCRIPTION_SUCCESS
} from './constants';
import { RESET_APP } from 'redux/app/constants';

const subscriptionTokenVerifiedReducer = (state = null, action) => {
  switch (action.type) {
    case GET_SUBSCRIPTION_TOKEN_DATA_SUCCESS:
      return fromJS(action.payload);
    case GET_SUBSCRIPTION_TOKEN_DATA:
    case GET_SUBSCRIPTION_TOKEN_DATA_FAILED:
    case LOCATION_CHANGE:
    case RESET_APP:
      return null;
    default:
      return state;
  }
};

const subscriptionTokenVerifiedIsFetchingReducer = (state = false, action) => {
  switch (action.type) {
    case GET_SUBSCRIPTION_TOKEN_DATA:
      return true;
    case GET_SUBSCRIPTION_TOKEN_DATA_FAILED:
    case GET_SUBSCRIPTION_TOKEN_DATA_SUCCESS:
    case RESET_APP:
      return false;
    default:
      return state;
  }
};

const handleElectronicMsgSubscriptionIsFetchingReducer = (
  state = false,
  action
) => {
  switch (action.type) {
    case HANDLE_ELECTRONIC_MSG_SUBSCRIPTION:
      return true;
    case HANDLE_ELECTRONIC_MSG_SUBSCRIPTION_SUCCESS:
    case HANDLE_ELECTRONIC_MSG_SUBSCRIPTION_FAILED:
    case RESET_APP:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  subscriptionTokenVerified: subscriptionTokenVerifiedReducer,
  subscriptionTokenVerifiedIsFetching:
    subscriptionTokenVerifiedIsFetchingReducer,
  handleElectronicMsgSubscriptionIsFetching:
    handleElectronicMsgSubscriptionIsFetchingReducer
});
