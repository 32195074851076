import { unSubscribeRoutes } from './ElectronicMessage';
import { resourcesAppRoutes } from './Resources/Apps';
import { resourcesRoutes } from './Resources/LoanDocuments';

const routes = [
  // {
  //   key: 'home_route',
  //   path: ROUTES.HOME,
  //   title: 'SVC',
  //   pageTitle: 'Home',
  //   exact: true,
  //   component: Home
  // },
  ...unSubscribeRoutes,
  ...resourcesRoutes,
  ...resourcesAppRoutes
];
export default routes;
