import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import LoadingPage from 'components/common/LoadingPage';

import { getLoanDocumentByToken } from 'redux/resources/actions';
import { saveEntryToken } from 'redux/tokens/actions';
import { replace } from 'connected-react-router';
import { ROUTES } from 'consts/routes';

function LoanDocumentLandingResource() {
  const dispatch = useDispatch();
  const { token } = useParams();

  useEffect(() => {
    if (token) {
      dispatch(replace(ROUTES.RESOURCES.LOAN.DOCUMENTS.BASE));
      dispatch(saveEntryToken(token));
      dispatch(getLoanDocumentByToken({ name: 'svcfindoc' }));
    }
  }, []);

  return <LoadingPage />;
}

export default LoanDocumentLandingResource;
