import React from 'react';
import styled from 'styled-components';

import { Card } from 'antd';
import NotFound from 'components/common/NotFound';
import Icon from '@ant-design/icons';

import { WIDTH_BREAKPOINT_MD } from 'theme/variables';
import classNames from 'classnames';
import { Title } from '../Typography';

const ContentCard = styled(Card)`
  .ant-card-head {
    padding: 0;
    min-height: auto;
  }

  .ant-card-head-title {
    padding: 10px 0;
  }

  .ant-card-body {
    padding: 10px 0;
  }
`;

export const Container = styled.div`
  padding: 20px;
  border: 1px solid #f0f0f0;
`;

export const LogoContainer = styled.div`
  &:hover img {
    cursor: pointer;
  }

  @media only screen and (max-width: ${WIDTH_BREAKPOINT_MD}) {
    & {
      align-self: flex-start;
    }
  }
`;

export const Actions = styled.div`
  width: auto;
  text-align: right;
  display: inline-block;

  .ant-btn + .ant-btn {
    margin-left: 10px;
  }
`;

export const UserListContainer = styled.div``;

function Content({ title, actions, className, children, ...props }) {
  const classes = classNames({ className });
  // renderTitle = () => {
  //   const { title, subtitle, actions, content } = this.props;
  //   return (
  //     <ContentTitle
  //       className={
  //         content
  //           ? `${this.props.className} with-divisor`
  //           : `${this.props.className} without-divisor`
  //       }
  //     >
  //       <Title>
  //         <span>{title}</span>
  //         {subtitle ? <span className="subtitle">{subtitle}</span> : null}
  //       </Title>
  //       {actions ? <Actions>{actions}</Actions> : null}
  //     </ContentTitle>
  //   );
  // };

  return (
    <ContentCard
      className={classes}
      title={title ? <Title>{title}</Title> : null}
      extra={actions ? actions : null}
      {...props}
    >
      {children}
    </ContentCard>
  );
}

export function ContentNotFound({ text, icon }) {
  return (
    <NotFound>
      {icon ? <Icon component={icon} /> : null}
      <span>{text}</span>
      {this.props.children}
    </NotFound>
  );
}

export default Content;
