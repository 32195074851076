import React from 'react';

import ExceptionPage from '../ExceptionPage';

const NotFound = () => {
  return (
    <ExceptionPage
      titlePage="Sorry, page not found."
      message={'The resource you are trying to access does not exist.'}
    />
  );
};

export default NotFound;
