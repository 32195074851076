import React from 'react';
import { Provider } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router/immutable';
import history from 'utils/history';
import store from 'redux/store';
import enUS from 'antd/es/locale/en_US';
import { IntlProvider } from 'react-intl';
import { configAxiosInstances } from 'api/axios';

import GlobalStyles from 'theme/global-styles';

import { ConfigProvider } from 'antd';

import { ServiceWorkerProvider } from 'components/services/ServiceWorker';
import NewVersion from 'components/services/ServiceWorker/NewVersion';

import { SVC_THEME } from 'theme';
import AppLayout from './layouts/AppLayout';

configAxiosInstances(store);

function App() {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <IntlProvider locale="en-US" defaultLocale="en-US">
          <ConfigProvider locale={enUS}>
            <GlobalStyles />
            <ServiceWorkerProvider>
              <NewVersion />
            </ServiceWorkerProvider>
            <ConfigProvider theme={SVC_THEME}>
              <AppLayout />
            </ConfigProvider>
          </ConfigProvider>
        </IntlProvider>
      </ConnectedRouter>
    </Provider>
  );
}

export default App;
