import React from 'react';
import { useSelector } from 'react-redux';
import LoadingPage from 'components/common/LoadingPage';
import {
  selectLoanDocumentByTokenFailed,
  selectLoanDocumentByTokenIsFetching,
  selectLoanDocumentByTokenSuccess
} from 'redux/resources/selectors';
import { selectCurrentToken } from 'redux/tokens/selectors';
import NotFound from 'components/common/Pages/NotFound';
import ErrorMessage, { SuccessMessage } from 'components/common/Message';

function LoanDocumentView() {
  const loanDocumentByTokenIsFetching = useSelector(
    selectLoanDocumentByTokenIsFetching
  );
  const isFailed = useSelector(selectLoanDocumentByTokenFailed);
  const isSuccess = useSelector(selectLoanDocumentByTokenSuccess);

  const currentToken = useSelector(selectCurrentToken);
  const isFetching = loanDocumentByTokenIsFetching || currentToken;

  if (isFetching) {
    return <LoadingPage />;
  }

  if (isFailed) {
    return <ErrorMessage title="Document could not be downloaded" />;
  }

  if (isSuccess) {
    return <SuccessMessage title={'Document downloaded successfully'} />;
  }

  return <NotFound />;
}

export default LoanDocumentView;
