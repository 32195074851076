import { tokenAxios, regularAxios } from './axios';

/**
 * Get subscription token data
 *
 * @returns {Promise}
 */
export function getEmailUnsubscribeData() {
  return tokenAxios.get(`/v1/EmailUnsubscribe`);
}

/**
 * Handle subscription decision
 *
 * @param {boolean} subscription
 * @param {object} data
 * @returns {Promise}
 */
export function handleElectronicMessageRequest(subscription, data) {
  let functionToCall = tokenAxios.delete;

  if (!subscription) {
    functionToCall = tokenAxios.post;
  }

  return functionToCall(`/v1/EmailUnsubscribe`, data);
}

export function getLoanDocumentRequest() {
  return tokenAxios.get(`/v1/LoanDocuments/PDF`, {
    responseType: 'blob'
  });
}
