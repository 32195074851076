import React, { memo } from 'react';

import { Layout } from 'antd';
import Navigation from './Navigation';
import CopyRight from 'components/common/CopyRight';
const { Footer } = Layout;

export default memo(function index() {
  return (
    <Footer
      style={{
        background: '#333f48',
        padding: '3rem',
        textAlign: 'center',
        color: 'white'
      }}
    >
      Service Finance Company, LLC | <CopyRight /> | NMLS 140908
      {/* <p style={{ textAlign: 'center' }}>
        In New Hampshire: dba Service Finance Enterprises Of Boca Raton In
        Texas: dba Service Finance Enterprises of Texas, LLC In New York: dba
        Finance Service Company In Arizona: dba Service Finance Enterprises of
        Boca Raton, LLC (FN)
      </p> */}
      {/* <Navigation /> */}
    </Footer>
  );
});
