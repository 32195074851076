/**
 * Create the store with asynchronously loaded reducers
 */

import { createStore, applyMiddleware, compose } from 'redux';
import { fromJS } from 'immutable';
import { routerMiddleware } from 'connected-react-router/immutable';
import createSagaMiddleware from 'redux-saga';

import pick from 'lodash/pick';
import history from 'utils/history';

// Import state persistance utilities
import { loadState, saveState } from 'utils/storage';
import electronicMsgSubscription from './electronicMsgSubscription/sagas';
import resources from './resources/sagas';
import createReducer from './reducers';

const sagaMiddleware = createSagaMiddleware();

const configureStore = (initialState = {}, history) => {
  // Create the store with two middlewares
  // 1. sagaMiddleware: Makes redux-sagas work
  // 2. routerMiddleware: Syncs the location/URL path to the state
  const middlewares = [sagaMiddleware, routerMiddleware(history)];

  const enhancers = [applyMiddleware(...middlewares)];

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  /* eslint-disable no-underscore-dangle */
  const composeEnhancers =
    process.env.NODE_ENV !== 'production' &&
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      : compose;
  /* eslint-enable */

  const store = createStore(
    createReducer(history),
    fromJS(initialState),
    composeEnhancers(...enhancers)
  );

  // Extensions
  store.runSaga = sagaMiddleware.run;

  // Async reducer registry
  store.asyncReducers = {};
  // Saga registry
  store.asyncSagas = {
    // appSaga,
    electronicMsgSubscription,
    resources
  };

  // appSaga.map(store.runSaga);
  electronicMsgSubscription.map(store.runSaga);
  resources.map(store.runSaga);

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      import('./reducers').then(reducerModule => {
        const createReducers = reducerModule.default;
        const nextReducers = createReducers(history, store.asyncReducers);

        store.replaceReducer(nextReducers);
      });
    });
  }

  return store;
};

// Create redux store with history
const initialState = loadState();

const store = configureStore(initialState, history);

// Sync state and session storage
store.subscribe(() => {
  const storeState = store.getState().toJS();

  saveState(
    pick(storeState, [
      'router',
      'tokens',
      'electronicMsgSubscription',
      'resources'
    ])
  );
});

export default store;
