import React from 'react';

import { Spin } from 'antd';

import { LoadingOutlined } from '@ant-design/icons';

export const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 42,
      margin: '0 auto'
    }}
    spin
  />
);

function LoadingPage() {
  return (
    <div
      style={{
        display: 'flex',
        height: '400px',
        justifyContent: 'center'
      }}
    >
      <Spin indicator={antIcon} style={{ alignSelf: 'center' }} />
    </div>
  );
}

export default LoadingPage;
