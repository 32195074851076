import axios from 'axios';
import store from 'redux/store';
import { BASE_URL, BASE_API_URL } from 'config/variables';
import { responseOk, responseError } from './interceptors';

const TIME_OUT = 60000;
export const TIME_OUT_MESSAGE = 'timeout';

/**
 * Creates a cancellable axios instance
 * @param {object} config
 */
function createCancelableAxios(config) {
  let cancel;
  const instance = axios.create({
    ...config,
    cancelToken: new axios.CancelToken(c => {
      cancel = c;
    })
  });

  instance.cancel = message => {
    cancel(message);
    instance.defaults.cancelToken = new axios.CancelToken(c => {
      cancel = c;
    });
  };

  return instance;
}

axios.defaults.timeoutErrorMessage = TIME_OUT_MESSAGE;

axios.defaults.headers.post['Content-Type'] = 'application/json';

const regularAxios = createCancelableAxios({
  baseURL: BASE_URL,
  timeout: TIME_OUT
});

const tokenAxios = createCancelableAxios({
  baseURL: BASE_API_URL,
  timeout: TIME_OUT
});

regularAxios.defaults.headers.common['Content-Type'] = 'application/json';

tokenAxios.defaults.headers.common['Content-Type'] = 'application/json';

tokenAxios.interceptors.request.use(config => {
  const currentToken = store.getState().getIn(['tokens', 'currentToken']);

  if (currentToken) {
    config.headers.Authorization = `Bearer ${currentToken}`;
  }

  return config;
});

export const configAxiosInstances = store => {
  // Axios interceptors for all API request as an unauthenticated user
  regularAxios.interceptors.response.use(responseOk, responseError(store));

  tokenAxios.interceptors.response.use(responseOk);
};

const isCancelledRequestException = axios.isCancel;

export { regularAxios, tokenAxios, isCancelledRequestException };
