import { Result } from 'antd';
import React from 'react';

import errorImg from 'images/error.svg';
import successImg from 'images/success.svg';
import { useIsMobile } from 'hooks/responsive';

const MOBILE_WIDTH = '160px';
const DEFAULT_WIDTH = '400px';

function ErrorMessage({
  title = `We're sorry, an error has occured`,
  message = 'Something happend. Please try again or contact us.'
}) {
  const isMobile = useIsMobile();
  const width = isMobile ? MOBILE_WIDTH : DEFAULT_WIDTH;
  return (
    <Result
      status="error"
      title={title}
      subTitle={message}
      icon={<img src={errorImg} alt="Error message" style={{ width }} />}
    />
  );
}

export function SuccessMessage({
  title = 'Success!',
  message = 'Thank you for choosing us.'
}) {
  const isMobile = useIsMobile();
  const width = isMobile ? MOBILE_WIDTH : DEFAULT_WIDTH;
  return (
    <Result
      status="success"
      title={title}
      subTitle={message}
      icon={<img src={successImg} alt="Success message" style={{ width }} />}
    />
  );
}

export default ErrorMessage;
