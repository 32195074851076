export const GET_SUBSCRIPTION_TOKEN_DATA =
  'balder/ElectronicMessages/GET_SUBSCRIPTION_TOKEN_DATA';
export const GET_SUBSCRIPTION_TOKEN_DATA_SUCCESS =
  'balder/ElectronicMessages/GET_SUBSCRIPTION_TOKEN_DATA_SUCCESS';
export const GET_SUBSCRIPTION_TOKEN_DATA_FAILED =
  'balder/ElectronicMessages/GET_SUBSCRIPTION_TOKEN_DATA_FAILED';

export const HANDLE_ELECTRONIC_MSG_SUBSCRIPTION =
  'balder/ElectronicMessages/HANDLE_ELECTRONIC_MSG_SUBSCRIPTION';
export const HANDLE_ELECTRONIC_MSG_SUBSCRIPTION_SUCCESS =
  'balder/ElectronicMessages/HANDLE_ELECTRONIC_MSG_SUBSCRIPTION_SUCCESS';
export const HANDLE_ELECTRONIC_MSG_SUBSCRIPTION_FAILED =
  'balder/ElectronicMessages/HANDLE_ELECTRONIC_MSG_SUBSCRIPTION_FAILED';
