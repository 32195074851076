import { ROUTES } from 'consts/routes';

import AppResource from 'components/views/Resources/App';

export const resourceAppBaseRoute = {
  key: 'app_download',
  path: ROUTES.RESOURCES.APPS.WITH_TYPE,
  title: 'Download app',
  exact: true,
  component: AppResource
};

export const resourcesAppRoutes = [resourceAppBaseRoute];
