import { ACTION_COLOR, FONT_COLOR, MAIN_COLOR } from './variables';

export const SVC_THEME = {
  token: {
    colorPrimary: MAIN_COLOR,
    colorLink: ACTION_COLOR,
    fontSizeHeading1: '1rem',
    colorText: FONT_COLOR,
    colorIcon: FONT_COLOR
  }
};
