import React from 'react';

import { LogoContainer } from 'components/common/Content';

import { useIsMobile } from 'hooks/responsive';

import logoImg from 'images/logo.89e95883.png';
import logoImgMobile from 'images/mobile_variant.svg';
import { SubTitle, Title } from '../Typography';
import isEmpty from 'lodash/isEmpty';

const Logo = ({ title = false, subtitle = false }) => {
  const isMobile = useIsMobile();
  const SERVICE_FINANCE_URL = `https://www.svcfin.com/`;

  const width = isMobile ? '160px' : '440px';

  return (
    <LogoContainer>
      <a
        target="blank"
        rel="noopener noreferrer"
        href={SERVICE_FINANCE_URL}
        alt="Service Finance Company"
      >
        <img
          className="logo"
          src={isMobile ? logoImgMobile : logoImg}
          alt="Service Finance Company Logo"
          style={{ width }}
        />
      </a>
      {!isEmpty(title) ? (
        <Title style={{ margin: '10px 0 0' }}>{title}</Title>
      ) : null}
      {!isEmpty(subtitle) ? (
        <SubTitle style={{ margin: 0 }}>{subtitle}</SubTitle>
      ) : null}
    </LogoContainer>
  );
};

export default Logo;
