import React, { memo } from 'react';

import { Layout } from 'antd';

// import Navigation from './Navigation';
import Logo from 'components/common/Logo';

const { Header } = Layout;

function AppHeader() {
  return (
    <Header
      style={{
        display: 'flex',
        background: 'white',
        padding: '.5rem 1rem',
        justifyContent: 'space-between',
        height: 'auto'
        // position: 'fixed',
        // width: '100%'
      }}
    >
      <Logo />
      {/* <Navigation /> */}
    </Header>
  );
}

export default memo(AppHeader);
