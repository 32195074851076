import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import NotFound from 'components/common/Pages/NotFound';
import AppContent from '../AppContent';
import routes from 'config/routes';

function RenderRoute(route, key, Component) {
  if (route.redirectTo) {
    return (
      <Route
        key={key}
        path={route.path}
        exact={route.exact}
        render={() => <Redirect to={route.redirectTo} />}
      ></Route>
    );
  }

  return (
    <Route key={key} path={route.path} exact={route.exact}>
      <Component route={route} />
    </Route>
  );
}

function buildRoutesToRender(moduleRoutesConfig, component) {
  let routesToRender = [];

  moduleRoutesConfig.forEach((route, index) => {
    if (!route.external) {
      if (route.children) {
        routesToRender.push(...buildRoutesToRender(route.children, component));
      } else if (route.path && (route.component || route.redirectTo)) {
        routesToRender.push(
          RenderRoute(route, `route-${route.key}`, component)
        );
      }
    }
  });

  return routesToRender;
}

function AppRoutes({ component }) {
  const routesToRender = buildRoutesToRender(routes, component);

  return (
    <Switch>
      {[...routesToRender]}
      <Route
        render={props => (
          <AppContent
            route={{ pageTitle: 'Not Found', component: NotFound }}
            {...props}
          />
        )}
      />
    </Switch>
  );
}

export default AppRoutes;
