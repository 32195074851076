import { makeActionCreator } from 'redux/utils';
import {
  GET_SUBSCRIPTION_TOKEN_DATA,
  GET_SUBSCRIPTION_TOKEN_DATA_FAILED,
  GET_SUBSCRIPTION_TOKEN_DATA_SUCCESS,
  HANDLE_ELECTRONIC_MSG_SUBSCRIPTION,
  HANDLE_ELECTRONIC_MSG_SUBSCRIPTION_FAILED,
  HANDLE_ELECTRONIC_MSG_SUBSCRIPTION_SUCCESS
} from './constants';

export const getSubscriptionTokenData = makeActionCreator(
  GET_SUBSCRIPTION_TOKEN_DATA
);
export const getSubscriptionTokenDataSuccess = makeActionCreator(
  GET_SUBSCRIPTION_TOKEN_DATA_SUCCESS,
  'payload'
);
export const getSubscriptionTokenDataFailed = makeActionCreator(
  GET_SUBSCRIPTION_TOKEN_DATA_FAILED,
  'error'
);

export const handleElectronicMessageSubscription = makeActionCreator(
  HANDLE_ELECTRONIC_MSG_SUBSCRIPTION,
  'payload'
);
export const handleElectronicMessageSubscriptionSuccess = makeActionCreator(
  HANDLE_ELECTRONIC_MSG_SUBSCRIPTION_SUCCESS,
  'payload'
);
export const handleElectronicMessageSubscriptionFailed = makeActionCreator(
  HANDLE_ELECTRONIC_MSG_SUBSCRIPTION_FAILED,
  'error'
);
