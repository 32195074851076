export const BASE_URL = process.env.REACT_APP_API_SFC_PATH;
export const BASE_API_URL = process.env.REACT_APP_API_HEIMDAL_PATH;
export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
//export const BASE_URL = 'https://sfcapitest.svcfin.com/api';

export const CLIENT_TYPE_BORROWER_NOMENCLATURE = 'borrower';
export const CLIENT_TYPE_DEALER_NOMENCLATURE = 'dealer';

export const BORROWER_ANDROID_APP_URL =
  'https://play.google.com/store/apps/details?id=com.svcfin.customerapp';
export const BORROWER_IOS_APP_URL =
  'https://apps.apple.com/us/app/service-finance-borrower-app/id1572527871';
export const DEALER_ANDROID_APP_URL =
  'https://play.google.com/store/apps/details?id=com.msi.servicefinance';
export const DEALER_IOS_APP_URL =
  'https://apps.apple.com/us/app/service-finance-dealer-app/id962933342';

export const OsTypes = {
  IOS: 'iOS',
  Android: 'Android',
  WindowsPhone: 'Windows Phone',
  Windows: 'Windows',
  MAC_OS: 'Mac OS'
};

export const APP_RESOURCE = {
  [CLIENT_TYPE_BORROWER_NOMENCLATURE]: {
    [OsTypes.IOS]: BORROWER_IOS_APP_URL,
    [OsTypes.Android]: BORROWER_ANDROID_APP_URL
  },
  [CLIENT_TYPE_DEALER_NOMENCLATURE]: {
    [OsTypes.IOS]: DEALER_IOS_APP_URL,
    [OsTypes.Android]: DEALER_ANDROID_APP_URL
  }
};
