import { call, put, takeLatest } from 'redux-saga/effects';
import FileSaver from 'file-saver';
import moment from 'moment';
import { apiRequest } from 'utils/sagas';

import { getLoanDocumentRequest } from 'api';

import {
  getLoanDocumentByTokenFailed,
  getLoanDocumentByTokenSuccess
} from './actions';
import { resetCurrentToken } from 'redux/tokens/actions';

import { GET_LOAN_DOCUMENT_BY_TOKEN } from './constants';
import { DATE_FORMAT_TO_SAVE } from 'theme/variables';

function* getLoanDocumentByTokenSaga() {
  yield takeLatest(GET_LOAN_DOCUMENT_BY_TOKEN, function* ({ payload }) {
    const response = yield call(apiRequest, getLoanDocumentRequest, []);

    if (!response.isSuccess) {
      yield put(getLoanDocumentByTokenFailed(response));
      yield put(resetCurrentToken());
      return;
    }

    const filename = response.request?.getResponseHeader('Content-Disposition');
    const filenameMatch = filename.match(/filename\*?=['"]?([^'";]+)\.pdf['"]?/); // prettier-ignore
    let documentName = payload.name;

    if (filenameMatch) {
      documentName = filenameMatch[1];
    }

    var blob = new Blob([response.data], {
      type: 'application/pdf;charset=utf-8'
    });

    FileSaver.saveAs(
      blob,
      `${documentName}_${moment().format(DATE_FORMAT_TO_SAVE)}.pdf`
    );

    yield put(getLoanDocumentByTokenSuccess());
    yield put(resetCurrentToken());
  });
}

const sagas = [getLoanDocumentByTokenSaga];
export default sagas;
