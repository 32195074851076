import { combineReducers } from 'redux-immutable';
import { fromJS } from 'immutable';

import { RESET_CURRENT_TOKEN, SAVE_ENTRY_TOKEN } from './constants';
import { RESET_APP } from 'redux/app/constants';

const currentTokenReducer = (state = null, action) => {
  switch (action.type) {
    case SAVE_ENTRY_TOKEN:
      return fromJS(action.token);
    case RESET_CURRENT_TOKEN:
    case RESET_APP:
      return null;
    default:
      return state;
  }
};

export default combineReducers({
  currentToken: currentTokenReducer
});
