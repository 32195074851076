import { ROUTES } from 'consts/routes';

import LoanDocumentView from 'components/views/Resources/LoanDocuments/LoanDocumentView';
import LoanDocumentLandingResource from 'components/views/Resources/LoanDocuments';

export const loanDocumentsResourcesBaseRoute = {
  key: 'loan_document_base',
  path: ROUTES.RESOURCES.LOAN.DOCUMENTS.BASE,
  title: 'Loan Document view',
  exact: true,
  component: LoanDocumentView
};

export const loanDocumentsResourcesWithTokenRoute = {
  key: 'loan_document_wtk',
  path: ROUTES.RESOURCES.LOAN.DOCUMENTS.WITH_TK,
  title: 'Loan Document view',
  exact: true,
  component: LoanDocumentLandingResource
};

export const resourcesRoutes = [
  loanDocumentsResourcesBaseRoute,
  loanDocumentsResourcesWithTokenRoute
];
