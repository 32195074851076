export const FAILED_STATUS = 'FAILED';
export const SUCCESSFUL_STATUS = 'SUCCESSFUL';
export const IS_FETCHING_STATUS = 'IS_FETCHING';

export const GET_LOAN_DOCUMENT_BY_TOKEN =
  'balder/resources/GET_LOAN_DOCUMENT_BY_TOKEN';
export const GET_LOAN_DOCUMENT_BY_TOKEN_SUCCESS =
  'balder/resources/GET_LOAN_DOCUMENT_BY_TOKEN_SUCCESS';
export const GET_LOAN_DOCUMENT_BY_TOKEN_FAILED =
  'balder/resources/GET_LOAN_DOCUMENT_BY_TOKEN_FAILED';
