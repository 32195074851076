import React, { memo, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import MobileStoreButton from 'react-mobile-store-button';
import appleImg from 'images/apple.svg';
import androidImg from 'images/android.svg';

import LoadingPage from 'components/common/LoadingPage';

import { isAndroid, isIOS, OsTypes } from 'react-device-detect';
import { APP_RESOURCE } from 'config/variables';
import { Result } from 'antd';
import { BORDER_DEFAULT } from 'theme/variables';

function AppListByType({ type }) {
  return (
    <Result
      icon={<></>}
      title="Download our apps today"
      extra={
        <div
          style={{
            background: 'white',
            margin: '0 auto',
            width: '80%',
            padding: 20,
            border: BORDER_DEFAULT
          }}
        >
          <MobileStoreButton
            store="ios"
            url={APP_RESOURCE[type][OsTypes.IOS]}
            linkProps={{
              title:
                'This button will take you to the Apple App Store to download.'
            }}
            linkStyles={{
              background: `url(${appleImg}) no-repeat`
            }}
          />
          <MobileStoreButton
            store="android"
            url={APP_RESOURCE[type][OsTypes.Android]}
            linkProps={{
              title:
                'This button will take you to the Android Store to download.'
            }}
            linkStyles={{
              background: `url(${androidImg}) no-repeat`
            }}
          />
        </div>
      }
    />
  );
}

function AppResource() {
  const [unknownDevice, setUnknownDevice] = useState(false);
  const { clientType } = useParams();

  const OS = {
    [isIOS]: OsTypes.IOS,
    [isAndroid]: OsTypes.Android
  };

  useEffect(() => {
    if (!OS[true]) {
      setUnknownDevice(true);
    } else {
      window.location.replace(APP_RESOURCE[clientType][OS[true]]);
    }
  }, []);

  return unknownDevice ? <AppListByType type={clientType} /> : <LoadingPage />;
}

export default memo(AppResource);
